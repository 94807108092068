/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Poppins-Black';
  src: url("../src/AFonts/Poppins-Black.ttf");
}
@font-face {
  font-family: 'Poppins-BlackItalic';
  src: url("../src/AFonts/Poppins-BlackItalic.ttf")
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url("../src/AFonts/Poppins-Bold.ttf")
}
@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url("../src/AFonts/Poppins-BoldItalic.ttf")
}
@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url("../src/AFonts/Poppins-ExtraBold.ttf")
}
@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: url("../src/AFonts/Poppins-ExtraBoldItalic.ttf")
}
@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url("../src/AFonts/Poppins-ExtraLight.ttf")
}
@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: url("../src/AFonts/Poppins-ExtraLightItalic.ttf")
}
@font-face {
  font-family: 'Poppins-Italic';
  src: url("../src/AFonts/Poppins-Italic.ttf")
}
@font-face {
  font-family: 'Poppins-Light';
  src: url("../src/AFonts/Poppins-Light.ttf")
}
@font-face {
  font-family: 'Poppins-LightItalic';
  src: url("../src/AFonts/Poppins-LightItalic.ttf")
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url("../src/AFonts/Poppins-Medium.ttf")
}
@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url("../src/AFonts/Poppins-MediumItalic.ttf")
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url("../src/AFonts/Poppins-Regular.ttf")
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url("../src/AFonts/Poppins-SemiBold.ttf")
}
@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url("../src/AFonts/Poppins-SemiBoldItalic.ttf")
}
@font-face {
  font-family: 'Poppins-Thin';
  src: url("../src/AFonts/Poppins-Thin.ttf")
}
@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url("../src/AFonts/Poppins-ThinItalic.ttf")
}

@font-face {
  font-family: 'Abuget';
  src: url("../src/AFonts/Abuget.ttf")
}
/* 

html {
  overflow: hidden !important;
} 
body {
  height: 100vh;
  overflow: auto;
}  */