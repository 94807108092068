$text-colorB: #044A95;
$txt-colorB: #044A8C;
$text-colorR: #C1272D;
$text-colorG: #464545;
$font-PoppinsBold: 'Poppins-Bold', Arial, Helvetica, sans-serif;
$font-PoppinsSemiBold: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
$font-PoppinsRegular: 'Poppins-Regular', Arial, Helvetica, sans-serif;
$font-PoppinsMedium: 'Poppins-Medium', Arial, Helvetica, sans-serif;
$font-PoppinsExtraBold: 'Poppins-ExtraBold', Arial, Helvetica, sans-serif;
$font-PoppinsItalic: 'Poppins-Italic', Arial, Helvetica, sans-serif;
$font-PoppinsBoldItalic: 'Poppins-BoldItalic', Arial, Helvetica, sans-serif;
$font-PoppinsMediumItalic: 'Poppins-MediumItalic', Arial, Helvetica, sans-serif;
$font-PoppinsSemiBoldItalic: 'Poppins-SemiBoldItalic', Arial, Helvetica, sans-serif;
$font-PoppinsExtraBoldItalic: 'Poppins-ExtraBoldItalic', Arial, Helvetica, sans-serif;


body{
   overflow-x: hidden !important;
}

@media (min-width: 1700px){
   body{
      // font-size: 1.55em;
      font-size: 1vw;
      overflow-x: hidden !important;
      .navbar-expand-sm{
         .container{
            max-width: 1800px;
            .d-sm-inline-flex{
               .navbar-nav li{
                  margin:  0 .5em;
               }
            }
         }  
      }
}
}
@media (max-width: 600px){
   body{
      font-size: .5em;
   }
   
}



@media (min-width: 768px) and (max-width: 1024px) {
   body{
      font-size: .65em;
}
}
.navbar{
   padding: 0 !important;
   @media (max-width: 600px){
      padding: 0.5rem 1rem !important;
      .navbar-toggler {
         /* background-color: transparent; */
         background: #fff !important; 
     }
     .navbar-h ul li a {
      border-bottom: 2px solid #fff;
      text-align: center;
      font-size: 1.3em;
  }
   }
   
}

.navbar-h{
   justify-content: center;
   color: #fff !important;
}
.navbar-h ul li a{
   color: #fff !important;
   font-family: $font-PoppinsRegular;
   font-size: 1em;
   transition: .5s ease-in-out;
}
.navbar-h ul li a:hover, .navbar-h ul li a:focus, .navbar-h ul li a:active {
   color: #fff !important;
}
// .navbar-h ul li a:focus, .navbar-h ul li a:active{
//    color: #fff !important;
//    font-weight: bold;
//    font-size: 1.05em;
// }
.navbar-container{
   background: $text-colorB;
   color: #fff !important;
   z-index: 10;
}
.navbar-info{
   background: $text-colorR;
   color: #fff;
   padding: .3em 0;
   z-index: 10 !important; 
   h4{
      margin: 0;
      font-family: $font-PoppinsRegular !important;
      text-align: center;
      font-size: .95em;
      a{
         text-decoration: none;
         
      }
      .coloured-1{
         color: #ffd041;
         cursor: pointer;
      }
   }
}
.carousel.slide{
   min-height: 24.5vw !important;
   @media (max-width: 600px){
      width: 100%;
      // min-height: 55vw !important;
   }
}
.carousel-item .img-fluid{
   width: 100%;
   min-height: 24.5vw !important;
   
   @media (max-width: 600px){
      // width: 100%;
      // min-height: 55vw !important;
   }
}
@media (max-width: 600px){
   .FraccContent{
      .carousel-item .img-fluid{
         object-fit: cover;
         height: 22em;
      }
   }
}



   @media (min-width: 500px){
      .carousel-control-next, .carousel-control-prev{
         width: 5%;
         opacity: 1 !important;
   }
   }
.carousel-control-next, .carousel-control-prev{
   .carousel-control-next-icon, .carousel-control-prev-icon {
      border: 2px solid #fff;
      padding: 1em !important;
      border-radius: 2em;
      background-size: 1.4em;
      // box-shadow: 0px 0px 2px #000;
   }
}
// .carousel.slide{
//    margin: 0 !important;
// }

.goUp{
   position: fixed;
   background: #044A8C;
   display: flex;
   z-index: 10;
   justify-content: center;
   align-items: center !important;
   flex-direction: row;
   width: 4em;
   height: 4em;
   cursor: pointer;
   bottom: 6%;
   right: 1%;
   opacity: .9;
   border-radius: 10%;
   box-shadow: 0px 0px 2px #000;
   h1{
      color: #fff;
      font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
      font-size: 3em;
      margin: 0;
      width: 100%;
      text-align: center;
   }
}

.content-absolute{
   position: absolute;
   z-index: 1;
   display: flex;
   width: 100%;
   margin: 2em 0em !important;
   justify-content: space-around;
   
   @media (max-width: 600px){
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      // font-size: 1.2em;
   }
   .parts{
      .part-1.titleP{
         position: relative;
         margin-bottom: 6em;
         h1{
            font-size: 3.5em;
            color: #fff;
            text-shadow: 2px 2px 5px #000;
            line-height: 1.2em;
            font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
            span{
               font-size: 1.5em
            }
         }
         h6{
            width: 100% !important;
            font-size: 10em;
            top: .3em;
            left: .45em;
            position: absolute;
            color: #faec21;
            font-family: 'Abuget', Arial, Helvetica, sans-serif;
            text-shadow: 2px 2px 5px #000;
            
         }
         @media (max-width: 600px){
            margin-right: 3em;
            margin-bottom: 4em;
            h1{
               text-align: center;
               font-size: 2.5em;
            }
            h6{
               left: 1.4em;
               top: .3em;
               width: max-content !important;
               font-size: 7em;
            }
         }
      }
      .part-2{
         display: flex;
         position: relative;
         .circle{
            display: flex;
            position: relative;
            img{
               width: auto;
               height: 7em;
               margin: 0 1em;
            }
            p{
               position: absolute;
               font-size: .9em;
               color: #fff;
               font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
               left: 4.6em;
               top: 2em;
               text-align: center;
               text-shadow: 2px 2px 5px #000;
            }
            .p1{
               left: 3.5em !important;
            }
         }
         h6{
            width: 100% !important;
            font-size: 10em;
            top: .2em;
            left: 2.5em;
            position: absolute;
            color: #faec21;
            font-family: 'Abuget', Arial, Helvetica, sans-serif;
            text-shadow: 2px 2px 5px #000;
            // display: none;
         }
         @media (max-width: 600px){
            .circle{
               justify-content: center !important;
               flex-direction: row;
               img{
                  height: 6em;
               }
               p{
                  font-size: .8em;
               }
            }
            h6{
               font-size: 7em;
            }
            h6{
               top: .5em;
               left: 2em;
            }
         }
         justify-content: center;
      }
   }
   .part3{
      display: flex;
      flex-direction: column;
      h1{
         font-size: 1.5em;
         color: #fff;
         font-family: 'Poppins-Light', Arial, Helvetica, sans-serif;
         text-shadow: 1px 1px 3px #000;
         line-height: 1em;
         .bolder{
            font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
         }
         .smaller{
            font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
            font-size: .6em;
         }
      }
      .img-container{
         display: flex;
         flex-direction: row;
         justify-content: center;
         
         margin-bottom: 1em;
         // display: none;
         width: 100%;
         flex-wrap: wrap;
         img{
            width: auto;
            height: 4em;
            align-items: center;
            align-content: center;
            image-rendering: crisp-edges;
            margin: 0em -.2em !important;
            
            @media (max-width: 600px){
               height: 2.5em;
            }
         }
         @media (max-width: 600px){
            gap: 1em;
         }
      }
      .btn-style{
         color: #ffffff;
         padding: .15em;
         border-radius: 2em;
         background: linear-gradient(90deg, #fa8919, #ff1565);
         text-align: center;
         width: max-content;
         box-shadow: 0px 0px 5px #000;
         cursor: pointer;
         h1{
            padding: .8em 1em !important;
            font-size: .8em;
            font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
            border-radius: 2em;
            text-shadow: none;
            border: 1px solid #fff;
            margin: 0 !important;
         }
      }
      @media (max-width: 600px){
         align-items: center;
         font-size: 1.5em;
         margin-top: 3.5em;
         width: 100%;
      }
   }
}
.secc-invierte{
   position: relative; 
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: .5em 0;
   height: 35em;
   margin-bottom: 7em;
   // margin: auto !important;
   h1{
      color: #fff;
      font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
      font-size: 2em;
      text-align: center;
      font-weight: 300 !important;
      span{
         font-family: 'Poppins-ExtraBoldItalic', Arial, Helvetica, sans-serif;
      }
   }
   .btn-style2{
      margin: auto;
   }
   iframe{
      margin: 1em 0;
      height: 30em;
      width: 60%;
      box-shadow: 0px 0px 5px #000;
      position: absolute;
      top: 10em;
   }
   @media (max-width: 600px){
      padding-top: 38em;
      padding-bottom: 33em;
      iframe{
         width: 95%;
         height: 25em;
         top: 50em ;
      } 
   }
  
}

.seccinviertePlus{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   .textoBtn2{
      .title{
         color: #044A8C;
         font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
         text-align: center;
         font-size: 1.5em;
         span{
            font-family: 'Poppins-ExtraBold', Arial, Helvetica, sans-serif;
         }
      }
      p{
         color: #044A8C;
         font-family: 'Poppins-ExtraBoldItalic', Arial, Helvetica, sans-serif;
         text-align: center;
         font-size: 1.1em;
      }
      .btn-style2{
         margin: auto;
      }
   }
}


.img-centro{
   display: flex;
   position: relative;
   margin-top: 6em;
   width: 100% !important;
   justify-content: center;

   // display: none;
   .circleY{
      width: auto;
      height: 35em;
      margin: 3em auto;
      position: relative;
      @media (max-width:600px){
         height: 14em;
      }
   }
   .imagesCen{
      position: absolute;
      height: 1px;
      width: 1px;
      .chica, .chico{
         position: absolute;
         width: auto;
         height: 36em;
         @media (max-width:600px){
            height: 15em;
         }
      }
      .chica{
         top: 5em;
         left: -19em;
         @media (max-width:600px){
            left: -7em;
         }
      }
      .chico{
         top: 5em;
         left: -3em;
         @media (max-width:600px){
            left: -1em;
         }
      }
   }
   
   .p1, .p2, .infoImg{
      position: absolute;
   }
   .infoImg{
      top: -4em;
      left: -33em;
      h1{
         color: #044A8C;
         font-size: 1.3em;
         width: 18em;
         font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
         span{
            font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
         }
      }
      p{
         color: #C1272D;
         font-family: 'Poppins-Bold';
         font-size: .9em;
      }
   }
   .p1, .p2{
      color: #044A8C;
      font-size: 3em;
      font-family: 'Poppins-BoldItalic', Arial, Helvetica, sans-serif;
   }
   .p1{
      left: -12.2em;
      top: 4em;
      text-align: right;
      @media (max-width:600px){
         left: -11.5em;
         top: 5em;
      }
   }
   .p2{
      left: 6.2em;
      top: 6.5em;
      @media (max-width:600px){
         left: 5.8em;
         top: 8em;
      }
   }
   .absoluteCentro{
      width: 1px;
      height: 1px;
      position: absolute;
   }
   @media (max-width: 600px){
      img{
         height: 16em;
         }
      .infoImg{
         top: -4em;
         left: -16em;
         font-size: .85em;
   }
   .p1, .p2{
      font-size: 1.2em;
   }
   }
}
.coloured-y{
   color: #faec21;
}
.secc-invertirTe{
   display: flex;
   flex-direction: column;
   width: 100%;
   padding-top: 1em;
   position: relative;
   border: 1px solid;
   // @media (min-width: 2560px){
   //    font-size: 1.2em;
   // }
   // display: none;
   h1{
      color: #fff;
      font-size: 2em;
      font-family: 'Poppins-BoldItalic', Arial, Helvetica, sans-serif;
      text-align: center;
      margin: 0;
   }
   
   .container-invertir{
      display: flex;
      flex-direction: row;
      margin: 2.5em 0;
      justify-content: space-around;
      position: relative;
      // display: none;
      .middle-line{
         width: 100% !important;
         background: #faec21;
         height: 8vh;
         position: absolute !important;
         bottom:60%;
         @media (min-width: 1700px) and (max-width: 2560px){
            bottom: 60%;
         }
      }
      div{
         display: flex;
         flex-direction: column;
         justify-content: space-around;
         position: relative;
         // width: 100% !important;
         img{
            width: 10em;
            height: auto;
            margin: auto;
            position: relative;
         }
         .infoInvert{
            display: flex;
            flex-direction: column;
            margin-top: 1em;
            width: 20em;
            h1{
               color: #faec21;
            }
            p{
               color: #fff;
               font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
               font-size: 1.2em;
               text-align: center;
            }
            @media (max-width: 600px){
               font-size: 1.3em;
            }
         }
      }
      @media (max-width: 600px){
         font-size: .55em;
         .middle-line{
            height: 5vh;
            bottom: 65%;
         }
         div{
            width: 100%;
            overflow: hidden;
            .infoInvert{
               overflow: hidden;
               width: 100%;
            }
         }
      }
   }
   a{
      display: flex;
      justify-content: center;
      
   }
   .btn-style2{
      position: absolute;
      bottom: -10%;
   }
}

.secPhone{
   display: flex;
   margin: 6em 0 4em 0;
   flex-direction: column;
   overflow-x: hidden;
.secPhones{
   position: relative;
    display: flex;
    justify-content: center !important;
   .imgPhones{
      width: 1px;
      height: 1px;
      position: absolute;
      img.hor{
         width: 22em;
         height: auto;
      }
      img.ver{
         width: auto;
         height: 23em;
      }
      img{
         position: absolute;
      }
      .imgPhone1{
         top: 0;
         left: -41em;
      }
      .imgPhone2{
         top: 0em;
         left: -17em;
      }
      .imgPhone3{
         top: 0em;
         left: 7em;
      }
      .imgPhone4{
         top: 0em;
         left: 20em;
      }
      .imgPhone5{
         top: 5em;
         left: 33em;
      }
      .imgPhone6{
         top: 16em;
         left: -55em;
      }
      .imgPhone7{
         top: 12em;
         left: -30.5em;
      }
      .imgPhone8{
         top: 12em;
         left: -17em;
      }
      .imgPhone9{
         top: 40em;
         left: -41em;
      }
      .imgPhone10{
         top: 28em;
         left: 20em;
      }
      .imgPhone11{
         top: 20em;
         left: 33em;
      }

      
   }
}
.info{
   display: flex;
   flex-direction: column;
   width: 35em;
   margin: 24em auto 0;
   h1{
      color: #044A8C;
      font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
      font-size: 2.5em;
      text-align: center;
      span{
         font-family: 'Poppins-Black', Arial, Helvetica, sans-serif;
      };
   }
   p{
      text-align: justify;
      margin-top: .5em;
      color: $text-colorG;
      font-family: 'Poppins-Italic', Arial, Helvetica, sans-serif;
      font-size: 1.3em;
   }
}
   @media (max-width:600px){
      font-size: .9em;
      .info{
         width: 85%;
         h1{
            font-size: 2.3em;
         }
         p{
            font-size: 1.5em;
         }
      }
   }

}

.seccParaiso{
   display: flex;
   flex-direction: row;
   padding: 2em 0;
   margin: auto;
   justify-content: center;
   align-items: flex-end;
   .infoBtn{
      display: flex;
      flex-direction: column;
      width: 30em;
      h1{
         color: #fff;
         font-family: 'Poppins-SemiBoldItalic', Arial, Helvetica, sans-serif;
         font-size: 2.5em;
      }
      p{
         color: #fff;
         font-family: 'Poppins-Italic', Arial, Helvetica, sans-serif;
         text-align: justify;
         font-size: 1.3em;
         span{
            font-family: 'Poppins-SemiBoldItalic', Arial, Helvetica, sans-serif;
         }
      }
   }
   .imgDuo{
      position: relative;
      margin-left: 5em;
      img.fix{
         width: 32em;
         height: auto;
      }
      img.abs{
         width: 30em;
         height: auto;
         position: absolute !important;
         top: -25%;
         left: -35%;
      }
   }
   @media (max-width: 600px){
      flex-direction: column;
      align-items: center;
      .imgDuo{
         margin: 3em auto;
         font-size: .8em;
      }
      .infoBtn{
         width: 95%;
         justify-content: center;
         align-items: center;
         // h1{
           
         // }
      }
   }
}

.seccDesarrollos{
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 70%;
   margin: 2em auto 1em auto;
   font-size: 1em;
   .seccDesarrollos-part1{
      display: flex;
      flex-direction: column;
      h1{
         color: #044A8C;
         font-size: 2em;
         font-family: 'Poppins-BoldItalic', Arial, Helvetica, sans-serif;
         text-align: center;
         margin: 0 !important;
      }
      p{
         color: #044A8C;
         font-size: 1.2em;
         font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
         text-align: center;
      }
      .desarrollosImg{
         display: flex;
         flex-direction: row;
         justify-content: center;
         @media (max-width: 600px){
            flex-wrap: wrap;
            font-size: 1em;
         }
         .content{
            .freyImg{
               height: 3em;
            }
            img{
               width: auto;
               height: 4em;
               margin: 0 1em;
               cursor: pointer;
               transform: scale(1);
               transition: .5s ease-in-out;
               @media (max-width: 600px){
                  margin: 1.5em;
                  height: 4em;
               }
            }
            h6{
               color: #044A8C;
               font-size: .8em;
               text-align: center;
               font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
               margin-top: 1.6em;
               margin-bottom: 0;
               transform: scale(0);
               transition: .5s ease-in-out;
               @media (max-width: 600px){
               margin-top: 0;
               }
            }
            p{
               color: #fff;
               font-size: 1em;
               text-align: center !important;
               font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
               margin: 0 auto !important;
               background-color: #044A8C;
               border-radius: 3em;
               width: 5em;
               transform: scale(0);
               transition: .5s ease-in-out;
            }
         }
         .content.seleccionado h6, .content.seleccionado p{
            transform: scale(1.1);
         }
         .content.seleccionado img{
            transform: scale(1.3);
         }
      }
   }
   @media (max-width: 600px){
      width: 100%;
   }
}


.FraccContent{
   display: flex;
   flex-direction: column;
   position: relative;
   .logoCentral{
      width: auto;
      justify-content: center;
      z-index: 1;
      display: flex;
      flex-direction: row;
      width: 100%;
      position: relative;
      img{
         position: absolute;
         width: auto;
         height: 9em;
         justify-content: center;
         top: -4.5em;
      }
   }
   .container-elements{
      padding-top: 1em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5em 8em;
      font-size: 1em;
      @media (max-width: 600px){
         flex-direction: column;
         padding-top: 7em;
         padding-left: 0;
         padding-right: 0;
      }
      .part{
         display: flex;
         flex-direction: column;
         width: 40%;
         margin: 0 auto;
         @media (max-width: 600px){
            width: 90%;
         }
         .redes{
            display: flex;
            flex-direction: column;
            div{
               margin: auto;
               img{
                  height: 2em;
                  width: auto;
                  margin: 0 .3em;
               }
            }
            p{
               text-align: center;
               color: #fff;
               font-size: 1em;
               font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
            }
         }
         .infoFracc{
            color: #fff;
            font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
            font-size: .8em;
            text-align: justify;
            ul{
               display: flex;
               flex-direction: column;
               width: 50%;
               margin: auto;

            }
         }
      }
   }
}


.bakground-BLinear{
   // background: linear-gradient(-180deg, #09478d, #072550);
   transition: 1s ease-in-out;
   .listAmenidades{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0em 0 2em 0;
      align-content: center;
      vertical-align: middle;
      @media (max-width: 600px){
         flex-wrap: wrap;
         margin: auto;
      }
      img{
         width: auto;
         height: 4em;
         margin: 0 .5em;
         @media (max-width: 600px){
            margin: .5em;
            height: 5em;
         }
      }
   }
}
.seccDesarrollos{
   display: flex;
}

.contacto.bg-image{
      position: relative;
      background-image: url("/elementos/inicio/fondoContacto.jpg");
      background-repeat: no-repeat;
      width: auto;
      height: 100vh;
      background-size: cover;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 5em;
      // background: #044A8C;
      
      .infoContacto{
         display: flex;
         flex-direction: column;
         background: #044a8d;
         padding: 0em 1em;
         width: 27.5em;
         // height: fit-content;
         height: 100%;
         // align-self: flex-end;
         // margin-bottom: 5em;
         // justify-content: flex-end;
         @media (min-width: 768px) and (max-width: 1024px){
            width: 38em;
         }
         img{
            width: 6em;
            height: auto;
            margin: .5em auto;
            @media (min-width: 1440px ){
               width: 5em;
            }
         }
         .info-box{
            margin-bottom: 1em;
            .direc, .telef, .correo, .horarios{
               div{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  i{
                     color: #fff;
                     font-size: 1.5em;
                     width: 1em;
                  }
                  h1{
                     color: #fff;
                     font-size: .8em;
                     margin: 0 .5em;
                     font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
                  }
               }
            }
            .direc, .telef, .correo, .horarios{
               p, h6{
                  color: #fff;
                  font-size: .7em;
                  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
                  margin: auto;
                  width: 75%;
                  // display: flex;
                  // flex-direction: row;
                  // justify-content: space-between;
                  @media (min-width: 768px) and (max-width: 1024px){
                     font-size: 1em;
                  }
                  span{
                     font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
                     font-size: .9em;
                  }
               }
            }
         }
      }
      .media{
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: flex-end;
         margin-top: auto;
         margin-bottom: .5em;
         align-self: flex-end;
         bottom: 0;
         a{
            margin: 0 .2em;
            img{
               height: 1.5em;
               width: 1.5em;
               margin: 0;
            }
         }
        
         p{
            margin: 0;
            font-size: 1.1em;
            color: #fff;
            font-family: 'Poppins-Medium', Arial, Helvetica, sans-serif;
         }
      }
      .contactar{
         background: rgba(255, 255, 255, 0.549);
         box-shadow: 0px 0px 3px #000;
         margin-left: 2em;
         padding: 1.5em 2em;
         width: fit-content;
         // margin-top: 5em;
         height: fit-content;
         align-self: flex-end;
         @media (max-width:600px){
            margin: 1em auto;
            align-self: inherit;
         }
         @media (min-width: 1024px) and (max-width: 1200px){
            font-size: 1.3em;
         }
         h1{
            font-size: 1.5em;
            color: #044A8C;
            font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
            margin: 0;
         }
         .inp p{
            color: #044A8C;
            font-size: .8em;
            font-family: 'Poppins-SemiBold', Arial, Helvetica, sans-serif;
            margin: 0;
         }
         .duo{
            display: flex;
            flex-direction: row;
            justify-content: space-between !important;
            
            input, select{
               width: 15em;
               padding: .5em .5em;
               
            }
         }
         input, select, textarea, input::placeholder, textarea::placeholder{
            padding: .5em .2em;
            width: 100%;
            background: #044A8C;
            border-radius: .5em;
            color: #fff;
            overflow: hidden;
            border: none;
           
         }
         textarea{
            border-radius: 0;
         }
         .inp{
            margin: .2em;
            input, select, textarea{
               font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
               font-size: .7em;
               box-shadow: 0px 0px 2px #000;
               
            }
         }
      }
      .btnContactar{
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         
         .btn-style2{
            margin-left: .5em;
            h1{
               font-size: .6em !important;
            }
         }
      }
      @media (max-width: 600px){
         flex-direction: column-reverse;
         align-items: center;
         // justify-content: inherit;
         height: auto;
         background-size: 100% 44%;
         padding-bottom: 0;
         .infoContacto{
            font-size: 1.43em;
            width: 100%;
            img{
               font-size: .8em;
            }
            .direc, .telef, .correo, .horarios{
               p, h6{
                  width: 83% !important;
               }
            }
         }
         .contactar{
            font-size: 1.1em;
         }
      }
   }

.mauto{
   margin: auto;
}
.shadowO{
   box-shadow: 0px 0px 1px #ffb830, 
               0px 0px 5px #ffb830,
               0px 0px 10px #ffb830 !important;
}
.btn-style2{
         color: #ffffff !important;
         padding: .2em;
         border-radius: 2em;
         background: linear-gradient(90deg, #ffb830, #ff8404);
         text-align: center;
         width: max-content;
         box-shadow: 0px 0px 5px #000;
         cursor: pointer;
         h1{
            color: #fff !important;
            padding: .8em 1em !important;
            font-size: 1.2em !important;
            font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif !important;
            border-radius: 2em;
            text-shadow: none !important;
            border: 1px solid #fff;
            margin: 0 !important;
         }
}
.background-b{
   background: #044A8C;
   width: 100%;
   
}

a{
   text-decoration: none !important;
}

.navbar-nav .nav-item{
   position: relative;
   .show{
      display: none;
   }
   div{
      position: absolute;
      display: flex;
      flex-direction: column;
      background: #044A8C;
      transform: scale(0);
      transition: .1s ease-in-out;
      
      a{
         text-align: center;
         padding: .1em;
         transition: .1s ease-in-out;
         padding: .5em;
         cursor: pointer;
      }
      a:hover{
         background: #fa8919;
      }
      }
}

@media (max-width: 600px){
   .navbar-nav{
      .nav-item{
      div{
         display: none;
         position: relative;
      }
      .hid{
         display: none;
      }
      .show{
         display: block;
      }
   }
}
}
.navbar-nav .nav-item:hover div{
   transform: scale(1)
}

.accesoClientes{
   @media (min-width: 2000px ){
      font-size: 1.2em;
   }
   .box{
      div{
         i{
            color: $text-colorB;
            background: #fff;
         }
      }
   }
}
.modal-content{
   border-radius: 1em;
   @media (min-width: 1700px) and (max-width: 2560px)
   {
    font-size: .9em;
   }
}
.modal-header{
   background: $text-colorB;
   position: relative;
   background: linear-gradient(0deg, #044A95, #01134B) !important;
   border-top-left-radius: 1em !important;
   border-top-right-radius: 1em !important;
   padding: 0 !important;
   h5{
      color: #fff;
      margin: auto;
      font-size: 2.3em;
      font-family: $font-PoppinsBold;
      @media (max-width: 600px){
         font-size: 1.8em;
      }
   }
   button.close{
      position: absolute;
      padding: 0 !important;
      margin: 0 !important;
      top: -.2em;
      right: 0em;
      color: #fff;
      opacity: 1;
      font-size: 2em;
      text-shadow: 0px 0px 3px #000;
   }
}
.accesoClientes{
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   @media (max-width: 768px){
      flex-direction: column;
      align-items: center;
      .box{
         width: 100% !important;
      }
   }
   h1{
      font-family: $font-PoppinsBold;
      color: $text-colorG;
      font-size: 2.3em;
      border-bottom: 5px solid $text-colorB;
      padding: 0;
      margin: .5em 0 !important;
      
   }
   .box{
      display: flex;
      flex-direction: column;
      width: 40%;
      a{
         text-decoration: none;
         color: #fff;
      }
      div{
         display: flex;
         flex-direction: row;
         width: 100%;
         background: linear-gradient(90deg, #044A95, #01134B);
         border-right: 8px solid #f7e921;
         margin: .5em 0;
         align-items: center;
         padding: .2em;
         transition: .5s ease-in-out;
         i{
            background-color: #fff;
            border-radius: .6em;
            margin: 0 .5em;
            font-size: 1.5em;
            padding: .1em .3em;
            color: $text-colorB;
         }
         h6{
            color: #fff;
            margin: .3em 0 !important;
            font-family: $font-PoppinsBold;
            font-size: 1.3em;
            text-align: left;
            width: 80%;
         }
      }
      div:hover{
         transform: scale(1.1);
         box-shadow: 0px 0px 5px #000;
      }
   }
}
@media (max-width: 600px){
   .navbar .navbar-toggler{
      background: transparent !important;
      border: 1px solid #fff;
   }  
   .navbar-light .navbar-brand{
      margin: auto;
      margin-right:30%;
   }
   body{
      font-size: .8em;
   }
}

@media (min-width: 1700px) and (max-width: 2560px){
   .modal-lg{
      max-width: 1200px;
   }
}

.ubicacion-container{
   display: flex;
   flex-direction: row;
   height: 100%;
   // align-items: flex-end;
   .ubicacion{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      
      h1{
         text-align: center;
         color: #fff;
         font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
         font-size: 1.5em;
         text-transform: uppercase;
      }
   }
   @media (max-width: 600px){
      flex-direction: column;
      row-gap: 2em;
      .ubicacion{
         width: 100%;
      }
   }
}


.invis{
   visibility:hidden;
}

.ubicacion-container{
   .media{
      bottom: 0;
   }
}

// .box-draw{
//    position: absolute;
//    .circledraw{
//       position: absolute;
//       width: 7em;
//       height: 7em;
//       border-radius: 50%;
//       box-shadow: 5px 2px 4px rgba(0, 0, 0, 0.7);
//       background-color: #0071b7;
//    }
//    h1{
//       position: absolute;
//       color: #f7e921;
//       font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
//       text-shadow: 0px 0px 2px #000;
//       top: 0em;
//       left: -.2em;
//       font-size: 6em;
//    }
// }

/* NOSOTROS */
.us-container{
   p, h1, h2, h3, h4, h5, h6, span{
      margin: 0;
   }
   .fondo{
      width: 100%;
   }
   .title{
      color: $txt-colorB;
      font-family: $font-PoppinsMedium;
      font-size: 1.3em;
      text-align: center;
      width: 70%;
      margin: 1em auto;

      span{
         font-family: $font-PoppinsBoldItalic;
      }
   }
   .container-info{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 85%;
      margin: auto;
      gap: 3em;
      .box-info{
         width: 50%;
         p{
            color: $txt-colorB;
            font-family: $font-PoppinsMedium;
            font-size: 1.1em;
            text-align: justify;
         }
         img{
            width: 100%;
            height: 25em;
         }
         .p1{
            margin-bottom: 1em;
         }
         .p2{
            margin-top: 1em;
         }
         iframe{
         width: 100%;
         height: 20em;
         }
      }
      @media (max-width: 600px){
         flex-direction: column;
         .box-info{
            width: 100%;
         }
      }
   }
   .fondoValores{
      background-image: url("/elementos/nosotros/fondoValores.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      width: 85%;
      flex-direction: column;
      justify-content: center;
      background-position: center;
      margin: 4em auto;
      .visMis-container{
         display: flex;
         flex-direction: row;
         justify-content: center;
         gap: 1em;
         margin-bottom: 3em;
         .box-vision, .box-mision{
            display: flex;
            flex-direction: column;
            width: 50%;
            h1{
               font-size: 6em;
               font-family: $font-PoppinsExtraBold;
            }
            p{
               font-size: 1.2em;
               font-family: $font-PoppinsMedium;
               margin-top: 1em;
            }
            img{
               width: 20em;
               height: auto;
            }
         }
         .box-vision{
            h1{
               text-align: right;
               color: #4d4d4d;
            }
            p{
               text-align: right;
               color: #4d4d4d;
            }
            img{
               
               align-self: flex-start;
            }
         }
         .box-mision{
            margin-top: 2em;
            h1{
               text-align: left;
               color: #044a87;
            }
            p{
               text-align: left;
               color: #044a87;
            }
            img{
               
               align-self: flex-end;
            }
         }
         @media (max-width: 600px){
            flex-direction: column;
            .box-vision, .box-mision{
               width: 100%;
            }
         }
      }
   }
   // .zigzag{
   //    width: 200px;
   //    height: 200px;
   //    background: #044A8C;
   // }
   // .zigzag::before{
   //    content: '';
   //    position: absolute;
   //    top: -10px;
   //    width: 100%;
   //    height: 10px;
   //    background: linear-gradient(45deg, transparent 33.333%, #333 33.333%, #333 66.667%, transparent
   //    66.667%),
   //    linear-gradient(-45deg, transparent 33.333%, #333 33.333%, #333 66.667%, transparent
   //    66.667%) !important;
   //    background-size: 20px 40px ;
   // }
   .valores-container{
      h1{
         font-size: 6em;
         font-family: $font-PoppinsMediumItalic;
         text-align: center;
         color: #4d4d4d;
         span{
            font-family: $font-PoppinsBold;
            color: #044a87;
         }
      }
      .imgValores{
         display: grid;
         grid-template-columns: repeat(5, 1fr);
         margin: auto;
         column-gap: 1em;
         img{
            height: 6em;
            width: auto;
            margin: 1em auto;
         }
      }
      @media (max-width: 600px){
         .imgValores{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin: auto;
            column-gap: 1em;
      }
      h1{
         font-size: 3em;
      }
      }
   }
   .datosAmistad{
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 2em 0;
      div{
         display: flex;
         flex-direction: column;
         align-items: center;
         // line-height: 1em;
         margin: 0 3em;
         width: max-content;
         h1{
            font-size: 5em;
            color: #fff;
            font-family: $font-PoppinsBold;
            text-align: center;
         }
         p{
            color: #fff;
            font-family: $font-PoppinsRegular;
            font-size: 1.2em;
            text-align: center;
         }
      }
      @media (max-width: 600px){
         justify-content: space-around;
         width: 100% !important; 
         font-size: .7em;
         div{
            h1{
               font-size: 3em;
            }
            p{
               font-size: 1.2em;
            }
         }
      }
   }
   .resenasCli-title{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2em 0;
      h6{
         font-family: $font-PoppinsBoldItalic;
         color: $txt-colorB;
         font-size: 2em;
      }
      p{
         color: $txt-colorB;
         font-size: 1em;
         font-family: $font-PoppinsMedium;
      }
   }
}


.container-resenas{
   position: relative;
   display: flex;
   flex-direction: row;
   width: 60em;
   overflow: hidden;
   margin: 0 auto 6em auto;
   // box-shadow: 0px 0px 2px #000;
   .resena-box{
      min-width: 60em;
      display: flex;
      flex-direction: row;
      transition: .5s ease-in-out;
      .part1{
         background: #f2f2f2;
         padding: 2em 2em;
         width: 70%;
         display: flex;
         flex-direction: column;
         h1{
            font-family: monospace, Arial, Helvetica, sans-serif;
            font-size: 4em;
            color: $txt-colorB;
            margin: 0 !important;
            line-height: 1em;
         }
         p{
            color: $txt-colorB;
            font-family: $font-PoppinsRegular;
            font-size: 1.3em;
            text-align: justify;
         }
         .imgDatos{
            display: flex;
            flex-direction: row;
            margin-top: 2em;
            img{
               width: auto;
               height: 6em;
               align-self: flex-end;
            }
            span{
               align-self: center;
               font-family: 'Poppins-Italic';
               color: $txt-colorB;
               font-size: 1.5em;
               margin-left: 1em;
            }
         }
         
      }
      .part2{
         width: 30%;
         background: #044a87;
         padding: 2em;
         display: flex;
         flex-direction: column;
         align-items: center;
         position: relative;
         img{
            width: 6em;
            height: auto;
            margin-bottom: 2em;
         }
         div{
            position: relative;
            margin-bottom: 1em;
            h1{
               // color: #e0b952;
               font-family: 'Abuget', Arial, Helvetica, sans-serif;
               font-size: 6em;
               background: linear-gradient(#eeb33e, #ffef89);
               -webkit-background-clip: text;
               background-clip: text;
               color: transparent;
            }
            h6{
               position: absolute;
               top: 5em;
               right: 0;
               background: linear-gradient(#eeb33e, #ffef89);
               -webkit-background-clip: text;
               background-clip: text;
               color: transparent;
               font-size: 1em;
               font-family: $font-PoppinsSemiBoldItalic;
            }
         }
         p{
            background: linear-gradient(#eeb33e, #ffef89);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-size: .9em;
            font-family: $font-PoppinsSemiBoldItalic;
         }
         .recorte{
            position: absolute;
            height: 1.5em;
            width: 1.5em;
            left: -1em;
            top: 20em;
            transform: rotate(45deg);
            background-color: #f2f2f2;
         }
      }
   }
   // @media (max-width: 600px){
   //    width: 25em;
   //    .resena-box{
   //       flex-direction: column;
   //       min-width: 25em;
   //       .part1{
   //          width: 100%;
   //          p{
   //             width: 100%;
   //          }
   //       }
   //       .part2{
   //          width: 100%;
   //       }
   //    }
   // }
}

.resena-todo{
   position: relative;
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: calc(60em + 10em);
   margin: auto;
   i{
      position: absolute;
      top: 7.5em;
      z-index: 1;
      font-size: 1.5em;
      color: $txt-colorB;
      border-radius: 50%;
      padding: .3em .5em;
      border: 2px solid $txt-colorB;
      cursor: pointer;
      align-items: center;
      vertical-align: middle;
   }
   i.left{
      left: 0;
   }
   i.right{
      right: 0;
   }
   // @media (max-width: 600px){
   //    width: calc(25em + 6em);
   // }
   @media (max-width: 600px){
      font-size: .4em;
   }
}
.resena-box.seleccionado{
   transform: translateX(-60em);
}

.freyImg{
   height: 1em;
}
.asaurusImg{
   height: 2em;
}

// .redes .wrap {
//    display: flex;
//    align-items: center;
//    justify-content: center;
  
//  }
 
//  .button {
//    min-width: 300px;
//    min-height: 60px;
//    font-family: 'Nunito', sans-serif;
//    font-size: 22px;
//    text-transform: uppercase;
//    letter-spacing: 1.3px;
//    font-weight: 700;
//    color: #313133;
//    background: #4FD1C5;
//    background: linear-gradient(90deg, rgba(129,230,217,1) 0%, rgba(79,209,197,1) 100%);
//    border: none;
//    border-radius: 1000px;
//    box-shadow: 12px 12px 24px rgba(79,209,197,.64);
//    transition: all 0.3s ease-in-out 0s;
//    cursor: pointer;
//    outline: none;
//    position: relative;
//    padding: 10px;
//    }
 
//  button::before {
//  content: '';
//    border-radius: 1000px;
//    min-width: calc(300px + 12px);
//    min-height: calc(60px + 12px);
//    border: 6px solid #00FFCB;
//    box-shadow: 0 0 60px rgba(0,255,203,.64);
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//    opacity: 0;
//    transition: all .3s ease-in-out 0s;
//  }
 
//  .button:hover, .button:focus {
//    color: #313133;
//    transform: translateY(-6px);
//  }
 
//  button:hover::before, button:focus::before {
//    opacity: 1;
//  }
 
//  button:after {
//    content: '';
//    width: 30px; height: 30px;
//    border-radius: 100%;
//    border: 6px solid #00FFCB;
//    position: absolute;
//    z-index: .5;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//    animation: ring 1.5s infinite;
//  }
 
//  button:hover::after, button:focus::after {
//    animation: none;
//    display: none;
//  }
 
//  @keyframes ring {
//    0% {
//      width: 0px;
//      height: 0px;
//      opacity: 1;
//    }
//    100% {
//      width: 300px;
//      height: 300px;
//      opacity: 0;
//    }
//  }

//  .redes div .comprar{
//     position: relative;
//  }
//  .redes div .comprar::before{
//    content: '';
//    border-radius: 1000px;
//    min-width: calc(300px + 12px);
//    min-height: calc(60px + 12px);
//    border: 6px solid #00FFCB;
//    box-shadow: 0 0 60px rgba(0,255,203,.64);
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//    opacity: 0;
//    transition: all .3s ease-in-out 0s;
//  }
//  .redes div .comprar::after {
//    content: '';
//    width: 60px;
//    height: 30px;
//    border-radius: 50%;
//    // border: 6px solid #00FFCB;
//    background: #ffd041;
//    position: absolute;
//    z-index: .5;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//    animation: reng .7s infinite;
//  }
//  @keyframes reng {
//    0% {
//      width: 0px;
//      height: 0px;
//      opacity: 1.5;
//    }
//    100% {
//      width: 300px;
//      height: 100px;
//      opacity: 0;
//    }
//  }


.avisoPriv{
   display: flex;
   flex-direction: row;
   position: absolute;
   background: hsla(208, 94%, 27%, 0.501);
   height: auto;
   padding: .3em;
   bottom: 1em;
   p, a{
      color: #FFF;
      font-size: 1em;
      font-family: 'Poppins-Regular';
      text-shadow: 0px 0px 4px #000;
      margin: 0;
   }
   p{
      margin-right: .5em;
   }
   a{
      cursor: pointer;
   }
   @media (max-width: 600px){
     display: none;
   }
}

.avisoPrivMovil{
   display: flex;
   flex-direction: row;
   background: $text-colorB;
   height: auto;
   padding: .3em;
   justify-content: center;
   p, a{
      color: #FFF;
      font-size: .9em;
      font-family: 'Poppins-Regular';
      text-shadow: 0px 0px 4px #000;
      margin: 0;
   }
   p{
      margin-right: .5em;
   }
   a{
      cursor: pointer;
   }
   @media (min-width: 768px){
      display: none;
   }
}
.terminoTitle{
   font-size: 1.8em;
}

// .navbar-expand-sm .navbar-collapse{
//    @media (min-width: 700px){
//       display: flex !important;
//       flex-basis: auto;
//    }
// }